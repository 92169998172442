<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { sliders } from "@/config/api/sliders";
import { projects } from "@/config/api/projects";
import { users } from "@/config/api/users";
import { companies } from "@/config/api/companies";

/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      sliderID: "",
      sliderInfo: undefined,
      companyInfo: [],
      projectExpertInfo: [],
      filteredProjects: [],
      url: "",
      title: " Details",
      loading: false,
      items: [
        {
          text: "All Sliders",
          to: { name: "Sliders" },
        },
        {
          text: "Slider Details",
          active: true,
        },
      ],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.sliderID = this.$route.query.id;
      this.loadInfo();
    }
  },
  methods: {
    loadInfo() {
      if (this.sliderID) {
        let api = sliders.model.get;
        api.params = {_id: this.sliderID}
        this.loading = true;
        this.generateAPI(api)
          .then((res) => {
            this.sliderInfo = res.data.sliders.docs[0];
            api = projects.model.get;
            this.generateAPI(api).then((res) => {
              this.filteredProjects = res.data.projects.filter((item) =>
                this.sliderInfo.project_ids.includes(item._id)
              );
            });
            api = users.getAll;
            this.generateAPI(api).then((res) => {
              this.projectExpertInfo = res.data.users.filter((item) =>
                this.sliderInfo.project_experts.includes(item._id)
              );
            });
            api = companies.get;
            this.generateAPI(api).then((res) => {
              this.companyInfo = res.data.companies.filter((item) =>
                this.sliderInfo.company_ids.includes(item._id)
              );
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading">
      <div style="text-align: center" class="mt-5 mb-5">
        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
      </div>
    </div>
    <div class="row" v-else-if="sliderInfo">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div style="text-align: end">
              <router-link
                :to="{
                  path: `/edit-slider?id=${sliderInfo && sliderInfo._id}`,
                }"
              >
                <b-button pill variant="primary"> Edit </b-button>
              </router-link>
            </div>

            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail" v-if="sliderInfo">
                  <div style="float: right">
                    <p>URL</p>
                    <p>{{ sliderInfo.url }}</p>
                  </div>
                  <div class="row">
                    <div class="col-md-11 col-9">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="product-1"
                          role="tabpanel"
                        >
                          <p>Slider Image</p>
                          <div>
                            <img
                              id="product-img"
                              :src="sliderInfo.image"
                              alt
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div v-if="sliderInfo.project_type" class="col-xl-7">
                <div class="mt-4 mt-xl-3">
                  <a
                    v-if="sliderInfo.project_type"
                    href="#"
                    class="text-primary"
                    >{{ sliderInfo.project_type.name }}</a
                  >
                  <h5 class="mt-1 mb-3">{{ sliderInfo.name_en }}</h5>
                  <h5 class="mt-1 mb-3">{{ sliderInfo.name_ar }}</h5>

                  <div class="d-inline-flex">
                    <div class="text-muted mr-3">
                      <span
                        v-if="sliderInfo.rating"
                        class="mdi mdi-star text-warning"
                        :v-for="index in sliderInfo.rating"
                        :key="index"
                      ></span>

                      <!-- <span class="mdi mdi-star"  :v-for="index in [1,2,3,4]" :key="index+10">
                          
                      </span> -->
                    </div>
                    <div class="text-muted">( {{ sliderInfo.views }} )</div>
                  </div>

                  <p class="mt-3">
                    To achieve this, it would be necessary to have uniform
                    pronunciation
                  </p>
                  <hr class="my-4" />

                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <h5 class="font-size-14">Specification :</h5>
                        <ul class="list-unstyled product-desc-list">
                          <li
                            v-for="(
                              key, value
                            ) in sliderInfo.custom_specifications"
                            :key="key"
                          >
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            {{ value }}
                            :
                            {{ key }}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <h5 class="font-size-14">Specifications :</h5>
                      <ul class="list-unstyled product-desc-list">
                        <li>
                          <i
                            class="mdi mdi-office-building text-primary mr-1 align-middle font-size-16"
                          ></i>
                          Area : {{ sliderInfo.project_area }} sqft.
                        </li>
                        <li>
                          <i
                            class="mdi mdi-cash-usd-outline text-primary mr-1 align-middle font-size-16"
                          ></i>
                          Cost : {{ sliderInfo.project_cost }}
                        </li>
                        <li>
                          <i
                            class="mdi mdi-timer text-primary mr-1 align-middle font-size-16"
                          ></i
                          >Starting Date : {{ sliderInfo.start_date }}
                        </li>
                        <li>
                          <i
                            class="mdi mdi-update text-primary mr-1 align-middle font-size-16"
                          ></i
                          >Ending Date : {{ sliderInfo.end_date }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="product-color mt-3">
                        <h5 class="font-size-14">Basic Info :</h5>
                        <ul class="list-unstyled product-desc-list">
                          <li>
                            Status:
                            {{ sliderInfo.deletedAt ? "Inactive" : "Active" }}
                          </li>
                          <li>
                            Verified:
                            {{ sliderInfo.is_verified ? "Yes" : "No" }}
                          </li>
                          <li>
                            Featured: {{ sliderInfo.featured ? "Yes" : "No" }}
                          </li>
                          <li>Status: {{ sliderInfo.status_code }}</li>
                          <li>
                            Location: {{ sliderInfo.location_ar }}/{{
                              sliderInfo.location_en
                            }}
                          </li>
                          <li v-if="sliderInfo.city">
                            City: {{ sliderInfo.city.name }}
                          </li>
                          <li v-if="sliderInfo.country">
                            Country: {{ sliderInfo.country.name }}
                          </li>
                          <li v-if="sliderInfo.certificates">
                            No. Certificates:
                            {{ sliderInfo.certificates.length }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <div class="mt-4">
              <h5 class="font-size-14 mb-3">More Info:</h5>
              <div class="product-desc">
                <b-tabs
                  class="nav-tabs-custom"
                  content-class="border border-top-0 p-4"
                >
                  <b-tab v-if="this.filteredProjects" title="Projects">
                    <div class="col-md-6">
                      <div class="product-color mt-3">
                        <a
                          href="#"
                          v-for="member in filteredProjects"
                          :key="member._id"
                        >
                          <div class="product-color-item">
                            <img
                              :src="member.cover_image"
                              alt
                              class="slider-images"
                            />
                          </div>
                          <p>{{ member.name_en }}</p>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="filteredProjects.length == 0">
                      No Projects
                    </div>
                  </b-tab>

                  <b-tab v-if="this.projectExpertInfo" title="Project Experts">
                    <div class="col-md-6">
                      <div class="product-color mt-3">
                        <a
                          href="#"
                          v-for="member in projectExpertInfo"
                          :key="member._id"
                        >
                          <div class="product-color-item">
                            <img
                              src="@/assets/mawj/logo.svg"
                              alt
                              class="slider-images"
                            />
                          </div>
                          <p>{{ member.name }}</p>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="projectExpertInfo.length == 0">
                      No Experts Available
                    </div>
                  </b-tab>

                  <b-tab v-if="this.companyInfo" title="Companies">
                    <div class="col-md-6">
                      <div class="product-color mt-3">
                        <a
                          href="#"
                          v-for="member in companyInfo"
                          :key="member._id"
                        >
                          <div class="product-color-item">
                            <img
                              :src="member.cover_image"
                              alt
                              class="slider-images"
                            />
                          </div>
                          <p>{{ member.display_name }}</p>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="companyInfo.length == 0">
                      No Companies
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->

    <!-- end row -->
  </Layout>
</template>
<style scoped>
#product-img {
  height: 300px;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
}
.slider-images {
  width: 100px;
  object-fit: cover;
}
</style>
